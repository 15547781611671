import React from "react";

import { Page, Container } from "@common/website/components/shared/layout";

class Broker extends React.Component {
  render() {
    return (
      <Page>
        <Container>Agent</Container>
      </Page>
    );
  }
}

export default Broker;
