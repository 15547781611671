import styled from "styled-components";

export const Page = styled.main`
	display: flex;
	flex: 1;
	padding-top: ${({ theme }) => theme.type.baseSpacing(3)};
	background: #000;
	color: #fff;
`;

export const PageHeader = styled.div`
	display: block;
	text-align: center;
`;

export const Container = styled.div`
	display: block;
	width: 100%;
	max-width: 1040px;
	margin: 0 auto;
	${({ pad, theme }) => (pad ? theme.spacing.default("padding", "sm", null, true, null, true) : null)};

	${({ theme, pad }) => theme.mediaQueries.desktop`
    	${pad ? theme.spacing.default("padding", "default", null, true, null, true) : null};
  `}
`;
